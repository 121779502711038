<template>
    <div class="community-collection-page" :class="{ 'label-page': labelList.length != 0 }">
        <div class="label-container" v-if="labelList.length">
            <div class="label-wrapper">
                <div class="arrow-left" @click="handleOffset(2)" v-show="isLeftArrowShow">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="label-box" ref="labelBox" :style="{ 'margin-left': isLeftArrowShow ? 0 : '30px' }">
                    <ul class="label-list" ref="labelListWrapper">
                        <!-- :style="{
                'background-color': item.bgColor,
              }" -->
                        <li
                            class="label-item"
                            v-for="(item, index) in labelList"
                            :key="item.id"
                            :class="{
                                checked: tid == item.id,
                                unchecked: tid != '' && tid != item.id
                            }"
                            @click="handleTagScreen(item, index)"
                        >
                            <span>{{ item.name }}</span>
                            <div class="checked-mask">
                                <i class="iconfont icon-duigou"></i>
                            </div>
                            <div class="unchecked-mask"></div>
                        </li>
                    </ul>
                </div>
                <div class="arrow-right" @click="handleOffset(1)" v-show="isRightArrowShow">
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="w-l works-content">
            <div class="works-container">
                <div class="works-wrapper" ref="cwaterfallContainer">
                    <HWaterfall
                        ref="cwaterfallWrapper"
                        :col="maxCols"
                        :width="225"
                        :gutterWidth="14"
                        :data="waterfallList"
                        @scroll="myScroll"
                        @loadmore="getData"
                        :loadDistance="100"
                        @finish="handleFinish"
                    >
                        <template>
                            <div
                                class="cell-item hover-item"
                                v-for="(item, index) in waterfallList"
                                :key="index"
                                @click="handleViewImage(item, index)"
                            >
                                <img
                                    v-if="item.obsThumbUrl"
                                    :src="item.obsThumbUrl"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width"
                                    :data-height="item.height"
                                />
                                <img
                                    v-else-if="item.type == 1"
                                    src="@/assets/images/coverdefault.png"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width - 4"
                                />
                                <img
                                    v-else-if="item.type == 0"
                                    src="@/assets/images/image_cover_default.png"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width - 4"
                                />
                                <div class="img-error" v-else>
                                    <i class="iconfont icon-zanwutupian" style="font-size: 100px"></i>
                                </div>
                                <div class="operation-container" :style="{ position: 'absolute', bottom: 0, color: '#fff' }">
                                    <div class="opt-btns opt-btns-name">
                                        <el-tooltip class="item" effect="dark" :content="item.url" placement="top-start">
                                            <span class="opt-name" style="cursor: pointer" @click="handleOpenUrl(item.url)">
                                                {{ item.url }}
                                            </span>
                                        </el-tooltip>
                                    </div>
                                    <div class="opt-btns-right">
                                        <div class="icon-wrapper" @click.stop="handleDownloadPic(item)">
                                            <img src="../../assets/images/icon/icon_download.png" alt="" />
                                        </div>
                                        <div v-if="item.userId != userId">
                                            <el-tooltip effect="dark" content="喜欢" placement="bottom">
                                                <div style="cursor: pointer" @click.stop="handlePoint(item)">
                                                    <i class="iconfont icon-aixinshixin" :class="{ active: item.hasPoint }"></i>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                        <div v-if="item.userId != userId">
                                            <el-tooltip effect="dark" content="保存到工作台" placement="bottom">
                                                <div style="cursor: pointer" @click.stop="handleSaveToWorkSpace(item)">
                                                    <i class="el-icon-document"></i>
                                                    <!-- <span class="txt">保存到工作台</span> -->
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <!-- 模型标记 -->
                                <div class="model-flag" v-if="item.type == 1">
                                    <img src="../../assets/images/icon/icon_models.png" alt="" />
                                </div>
                                <!-- 图片尺寸 -->
                                <div class="size" v-if="item.type == 0">
                                    {{ item.width + "x" + item.height }}
                                </div>
                            </div>
                        </template>
                    </HWaterfall>
                </div>

                <Empty v-if="isEmpty" description="没有找到相关作品" />
            </div>
        </div>
        <el-dialog :visible.sync="dialogShow" :close-on-click-modal="false" custom-class="work-info-dialog" :before-close="handleBeforeClose">
            <info-dialog :id="imgId"></info-dialog
        ></el-dialog>
    </div>
</template>

<script>
import { downPic, getNewArr } from "@/libs/tools";
import infoDialog from "./component/infoDialog.vue";
import { previewModel, new_downloadModel, downloadModelV2 } from "@/util/model";
import { Loading } from "element-ui";

export default {
    name: "workList",
    components: {
        infoDialog
    },
    data() {
        return {
            loadMore: true,
            diff: false,
            SUCCESS_CODE: 0,
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            waterfallList: [],
            pageParams: {
                pageSize: 50,
                pageNum: 0,
                total: 0,
                currentPage: 0
            },
            pageLoading: false,
            isEmpty: false,
            imgDataInfo: {},
            maxCols: 6,
            keywords: "", // 搜索关键字
            tid: "", // 标签id
            imgId: 0,
            dialogShow: false,
            labelList: [],
            isLeftArrowShow: false,
            isRightArrowShow: true,
            type: "",
            userId: "",
            loadingInstance: null
        };
    },
    created() {
        this.tid = this.getCacheListParams().tid;
        this.getData(true);
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.userId = userInfo.id;
        // 获取标签列表
        // this.getTagList();
    },
    mounted() {
        // this.caculateCol();
        let self = this;
        window.onresize = function () {
            self.caculateCol();
        };

        // EventBus.$on("searchWork", (data) => {
        //   if (data) {
        //     this.$nextTick(() => {
        //       this.isLeftArrowShow = false;
        //       if (this.labelList.length) {
        //         // 将left值重置为0
        //         this.$refs.labelListWrapper.style.left = 0 + "px";
        //       }
        //     });
        //   }
        //   this.keywords = data;
        //   this.getData(true);
        // });
    },
    watch: {
        $route: {
            handler(val) {
                this.keywords = val.query.keywords ? val.query.keywords : "";
                this.type = val.query.type ? val.query.type : "";
                // console.log(this.keywords);
                this.reset();
                this.getData(true);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handleDownloadPic(item) {
            this.pageLoading = true;
            let ids = item.id;
            console.log(item);
            this.$http
                .post("/download/download", null, { params: { ids } })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        if (item.type == 0) {
                            downPic(item.obsUrl, item.name, item.format);
                        } else if (item.type == 1) {
                            if (item.objectId) {
                                downloadModelV2(item.objectId, item.name);
                            } else {
                                new_downloadModel(item.realiboxSceneId, item.name);
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.pageLoading = false;
                })
                .catch(err => {
                    this.$message.error(err);
                    this.pageLoading = false;
                });
        },
        handleOpenUrl(url) {
            url && window.open(url);
        },
        setCacheListParams(params) {
            sessionStorage.setItem(`${this.$route.path}_list_params`, JSON.stringify(params || {}));
        },

        getCacheListParams() {
            const params = sessionStorage.getItem(`${this.$route.path}_list_params`);
            return params && params !== "undefined" ? JSON.parse(params) : {};
        },

        caculateCol() {
            let width = this.$refs.cwaterfallContainer.clientWidth;
            // this.maxCols = parseInt(width / 225);
            this.maxCols = parseInt(width / 225);
        },
        getLabelBoxWidth() {
            if (this.labelList.length) {
                this.$nextTick(() => {
                    // 外层标签容器宽度
                    let boxWidth = this.$refs.labelBox.clientWidth;
                    // 内层标签容器宽度
                    let wrapperWidth = this.$refs.labelListWrapper.clientWidth;
                    if (boxWidth < wrapperWidth) {
                        this.isLeftArrowShow = false;
                        this.isRightArrowShow = true;
                    } else {
                        this.isLeftArrowShow = false;
                        this.isRightArrowShow = false;
                    }
                });
            }
        },

        myScroll(res) {
            if (res.diff <= 100) {
                console.log("qty diff 加载");
                this.diff = true;
                this.getData();
            } else {
                this.diff = false;
            }
        },
        // 获取图片集资源
        getData(flag, tag) {
            if (!this.loadMore) return;
            this.loadMore = false;
            if (flag) {
                // this.labelList = [];
                this.waterfallList = [];
            }
            if (this.pageParams.currentPage * this.pageParams.pageSize > this.pageParams.total) {
                // this.$refs.waterfall.waterfallOver();
                return;
            }
            // this.loadingInstance = this.$loading({
            //   fullscreen: true,
            //   background: "rgba(0,0,0,.3)",
            // });
            // const el = document.getElementsByClassName("el-loading-mask")[0];
            // console.log(el);
            // document.onmousewheel = function () {
            //   return false;
            // };
            this.pageLoading = true;
            this.pageParams.pageNum++;
            let params = Object.assign({}, this.pageParams);
            params.search = this.keywords;
            params.type = this.type;
            params.tid = this.tid;

            // 缓存当前请求参数
            this.setCacheListParams(params);
            this.$http
                .get("/resource-info/comList", { params })
                .then(res => {
                    if (res.code == 0) {
                        let data = res.data.page;
                        data.records.forEach(item => {
                            item.checked = false;
                        });
                        this.pageParams.total = data.total;
                        this.totalImage = data.total;
                        this.pageParams.currentPage = data.current;
                        if (data.records.length) {
                            this.waterfallList = this.waterfallList.concat(data.records);
                            this.$nextTick(() => console.log("----workerList 渲染开始----", new Date().getTime()));
                        }

                        this.isEmpty = this.waterfallList.length ? false : true;
                        if (this.isEmpty) {
                            this.pageLoading = false;
                            // this.loadingInstance.close();
                        }

                        // 给taglist赋值
                        let tagList = [...res.data.tagList];

                        if (tagList.length) {
                            // let tagIndex = null;
                            tagList.forEach((item, index) => {
                                item.bgColor =
                                    "rgb(" +
                                    Math.floor(Math.random() * 200 + 55) +
                                    "," +
                                    Math.floor(Math.random() * 200 + 55) +
                                    "," +
                                    Math.floor(Math.random() * 200 + 55) +
                                    ")";
                                // if (flag && tag && item.id === tag.data.id) {
                                //     tagIndex = index;
                                // }
                            });
                            //**上当了 永远都会动 */
                            // if (tagIndex !== null && tagIndex !== tag.index) {
                            //     const data = tagList.splice(tagIndex, 1);
                            //     tagList.splice(tag.index, 0, data[0]);
                            // }
                        }
                        if (flag) {
                            if (this.labelList.length === 0) {
                                this.labelList = tagList;
                            }
                        } else {
                            if (this.labelList.length) {
                                // 标签去重操作
                                getNewArr(tagList, this.labelList, "id");
                            }
                        }
                    } else {
                        this.pageLoading = false;
                        // this.loadingInstance.close();
                    }
                    // this.getLabelBoxWidth();
                })
                .catch(err => {
                    this.waterfallList = [];
                    this.isEmpty = true;
                    this.pageLoading = false;
                    // this.loadingInstance.close();
                });
        },
        // 前往设计师页面
        gotoDesigner() {
            if (this.folderInfo.userId) {
                this.$router.push("/community/designer/" + this.folderInfo.userId);
            }
        },
        // 给图片点赞
        handlePoint(data) {
            let params = {
                id: data.id,
                status: data.hasPoint ? 0 : 1
            };
            this.$http.post("/point/modify", null, { params }).then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    // 改变状态
                    data.hasPoint = !data.hasPoint;
                }
            });
        },
        // 保存下载记录
        saveDownloadRecords(item) {
            this.pageLoading = true;
            let ids = item.id;
            this.$http
                .post("/download/download", null, { params: { ids } })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        downPic(item.obsUrl, item.name);
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.pageLoading = false;
                })
                .catch(err => {
                    this.$message.error(err);
                    this.pageLoading = false;
                });
        },
        // 下载图片
        downLoadImage(item) {
            this.saveDownloadRecords(item);
        },
        // 预览图片
        handleViewImage(data, index) {
            if (data.type == 1) {
                previewModel(data.id, data.objectId);
            } else {
                // this.dialogShow = true;
                // this.imgId = data.id;
                // let routeData = this.$router.resolve({
                //     path: "/community/imageDetail/" + data.id
                // });
                let routeData = this.$router.resolve({
                    path: `/community/imageDetail/${data.id}?pageNum=${Math.floor(index / 50) + 1}`
                });
                window.open(routeData.href, "_blank");
            }
        },
        handleBeforeClose(done) {
            done();
        },
        handleOffset(type) {
            // console.log(this.$refs.labelBox.clientWidth);
            let boxWidth = this.$refs.labelBox.clientWidth;
            let wrapperWidth = this.$refs.labelListWrapper.clientWidth;
            let offsetWidth = wrapperWidth - boxWidth;
            let left = this.$refs.labelListWrapper.style.left;
            let leftWidth = Number(left.substring(0, left.length - 2));
            // console.log(leftWidth);
            // console.log(offsetWidth);
            //   type为1是向左移动，2是向右移动
            if (type == 1) {
                this.isLeftArrowShow = true;
                if (Math.abs(leftWidth) < offsetWidth - 300) {
                    this.$refs.labelListWrapper.style.left = leftWidth - 300 + "px";
                } else {
                    this.$refs.labelListWrapper.style.left = -offsetWidth + "px";
                }
            } else {
                if (leftWidth < -300) {
                    this.$refs.labelListWrapper.style.left = leftWidth + 300 + "px";
                } else {
                    this.$refs.labelListWrapper.style.left = 0 + "px";
                    this.isLeftArrowShow = false;
                }
            }
        },
        // 获取标签列表
        getTagList() {
            this.$http.get("/com-tag-info/list").then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    let data = res.data;
                    data.forEach(item => {
                        item.bgColor =
                            "rgb(" +
                            Math.floor(Math.random() * 200 + 55) +
                            "," +
                            Math.floor(Math.random() * 200 + 55) +
                            "," +
                            Math.floor(Math.random() * 200 + 55) +
                            ")";
                    });
                    this.labelList = data;
                }
            });
        },
        // 标签筛选
        handleTagScreen(data, index) {
            console.log(index);
            if (this.tid == data.id) {
                this.tid = "";
            } else {
                this.tid = data.id;
            }
            this.reset();
            this.getData(true, { data, index });
        },
        handleSaveToWorkSpace(item) {
            console.log("保存到工作台！");
            let params = { rid: item.id };
            this.$http
                .post("/resource-info/collect", null, { params })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success("操作成功！");
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    this.$message.error("操作失败！");
                });
        },
        handleFinish() {
            console.log("----workerList 渲染完成----", new Date().getTime());
            this.loadMore = true;
            this.pageLoading = false;
            // this.loadingInstance.close();
            const state = this.scrollToTheEnd();
            if (this.diff && state) {
                this.getData();
            }
        },
        scrollToTheEnd() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight - 100) {
                return true;
            }
            return false;
        },
        reset() {
            this.loadMore = true;
            this.pageParams = {
                pageSize: 50,
                pageNum: 0,
                total: 0,
                currentPage: 0
            };
        }
    }
};
</script>
