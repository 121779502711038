import http from "@/config/http.js"
import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";

const SUCCESS_CODE = store.state.dic.SUCCESS_CODE;

// 下载模型
export const downloadModel = (sceneId) => {
    let params = { sceneId };
    http
        .get("/user-job/download", { params })
        .then((res) => {
            if (res.code == SUCCESS_CODE) {
                window.location.href = res.data;
            } else {
                Message.error("下载失败,请稍后重试！");
            }
        })
        .catch((err) => {
            Message.error("下载失败,请稍后重试！");
        });
}
export const new_downloadModel = (sceneId, name) => {
    let params = { sceneId };
    http
        .get("/user-job/download", { params })
        .then((res) => {
            if (res.code == SUCCESS_CODE) {
                http.get(res.data, { responseType: 'blob' }).then(res => {
                    function downloadFile(data, type, fileName) {
                        let blob = new Blob([data], { type: `application/${type};charset=utf-8` });
                        // 获取heads中的filename文件名
                        let downloadElement = document.createElement('a');
                        // 创建下载的链接
                        let href = window.URL.createObjectURL(blob);
                        downloadElement.href = href;
                        // 下载后文件名
                        downloadElement.download = fileName;
                        document.body.appendChild(downloadElement);
                        // 点击下载
                        downloadElement.click();
                        // 下载完成移除元素
                        document.body.removeChild(downloadElement);
                        // 释放掉blob对象
                        window.URL.revokeObjectURL(href);
                    }
                    downloadFile(res, "octet-stream", name)
                })
            } else {
                Message.error("下载失败,请稍后重试！");
            }
        })
        .catch((err) => {
            Message.error("下载失败,请稍后重试！");
        });
}

//零件模型下载
export const downloadModelV2 = (objectId, name) => {
    let params = { objectId };
    http
        .get("/user-job/download2", { params })
        .then((res) => {
            if (res.code == SUCCESS_CODE) {
                let data = JSON.parse(res.data);
                data.url && http.get(data.url, { responseType: 'blob' }).then(res => {
                    function downloadFile(data, type, fileName) {
                        let blob = new Blob([data], { type: `application/${type};charset=utf-8` });
                        // 获取heads中的filename文件名
                        let downloadElement = document.createElement('a');
                        // 创建下载的链接
                        let href = window.URL.createObjectURL(blob);
                        downloadElement.href = href;
                        // 下载后文件名
                        downloadElement.download = fileName;
                        document.body.appendChild(downloadElement);
                        // 点击下载
                        downloadElement.click();
                        // 下载完成移除元素
                        document.body.removeChild(downloadElement);
                        // 释放掉blob对象
                        window.URL.revokeObjectURL(href);
                    }
                    downloadFile(res, "octet-stream", name)
                })
            } else {
                Message.error("下载失败,请稍后重试！");
            }
        })
        .catch((err) => {
            Message.error("下载失败,请稍后重试！");
        });
}


// 删除模型
export const deleteModel = (sceneId, callback) => {
    http
        .delete("/user-job/" + sceneId)
        .then((res) => {
            if (res.code == SUCCESS_CODE) {
                Message.success("删除成功！");
                callback && callback(true);
            } else {
                Message.error("删除模型失败,请稍后重试！");
            }
        })
        .catch((err) => {
            Message.error("删除模型失败,请稍后重试！");
        });
}

// 预览模型 objectId存在时，是新模型数据，跳转model/view2页面
export const previewModel = (id, objectId) => {
    if(objectId){
        let routeData = router.resolve({
            path: "/model/viewer/" + id,
        });
        window.open(routeData.href, "_blank");
    }else{
        let routeData = router.resolve({
            // path: "/model/view/" + id + "/" + sceneId,
            path: "/model/view/" + id,
        });
        window.open(routeData.href, "_blank");
    }
}