<template>
  <div class="work-info-wrapper">
    <div class="div-scroll">
      <div class="img-wrapper">
        <img :src="dataInfo.obsUrl || dataInfo.obsThumbUrl" alt="" />
      </div>
    </div>
    <div class="info-wrapper">
      <div class="c-wrapper basic-wrapper">
        <ul>
          <li>
            <p class="name">作品名称</p>
            <el-tooltip
              class="item"
              effect="dark"
              :content="dataInfo.name"
              placement="top"
            >
              <p class="value ellipsis">
                {{ dataInfo.name }}
              </p>
            </el-tooltip>
          </li>
          <li>
            <p class="name">作品尺寸</p>
            <p class="value">
              {{ dataInfo.width + "*" + dataInfo.height }}
            </p>
          </li>
          <li>
            <p class="name">作品大小</p>
            <p class="value">
              <span v-if="dataInfo.size < 1024">{{ dataInfo.size }}KB</span>
              <span v-else>{{ (dataInfo.size / 1024).toFixed(2) }}MB</span>
            </p>
          </li>
          <li>
            <p class="name">作品类型</p>
            <p class="value">{{ dataInfo.format }}</p>
          </li>
          <li>
            <p class="name">创建时间</p>
            <p class="value">{{ dataInfo.createTime }}</p>
          </li>
        </ul>
      </div>
      <div class="c-wrapper colors-wrapper" v-if="colors.length">
        <ul class="prop-color-card">
          <li
            class="color-caard-item"
            v-for="(color, index) in colors"
            :key="index"
            :title="
              'rgb(' + color.rval + ',' + color.gval + ',' + color.bval + ')'
            "
            :style="{
              'background-color':
                'rgb(' + color.rval + ',' + color.gval + ',' + color.bval + ')',
            }"
            @click="handleColorScreen(color)"
          ></li>
        </ul>
      </div>
      <!-- 默认标签展示 -->
      <div class="c-wrapper" v-if="photoDefaultTagList.length">
        <div class="tag-wrapper">
          <el-tag v-for="tag in photoDefaultTagList" :key="tag.id" type="info">
            {{ tag.tagName }}
          </el-tag>
        </div>
      </div>
      <div class="c-wrapper user-wrapper">
        <div class="name">设计师信息</div>
        <div class="value">
          <el-avatar :size="50" :src="photo || circleUrl"></el-avatar>
          <span>{{ dataInfo.nickname }}</span>
        </div>
      </div>
      <div class="download-wrapper">
        <el-button @click.stop="downLoadImage" class="download-btn"
          >下载原图</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { downPic } from "@/libs/tools";
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dataInfo: {},
      colors: [],
      photo: "",
      dialogShow: false,
      photoDefaultTagList: [],
    };
  },
  props: {
    id: Number,
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(val) {
        console.log(val);
        this.dialogShow = val;
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler(val) {
        if (val) {
          this.getImageInfo(val);
          this.getPhotoDefaultTagList(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //   获取图片详情
    getImageInfo(id) {
      //  this.isLoading = true;
      this.$http
        .get("/resource-info/" + id)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data) {
              this.dataInfo = res.data.resourceInfo;
              //   this.directoryId = this.dataInfo.directoryId;
              this.colors = res.data.colors;
              this.photo = res.data.photo;
              //   if (res.data.users) {
              //     this.shareList = res.data.users;
              //   }
            }
          }
          //   this.isLoading = false;
        })
        .catch((err) => {
          this.dataInfo = {};
          this.isLoading = false;
        });
    },
    // 获取默认标签列表
    getPhotoDefaultTagList(id) {
      this.$http
        .get("/resource-com-tag/listOn/" + id)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.photoDefaultTagList = res.data;
          } else {
            this.photoDefaultTagList = [];
          }
        })
        .catch((err) => {
          this.photoDefaultTagList = [];
        });
    },
    // 保存下载记录
    saveDownloadRecords() {
      //   this.pageLoading = true;
      let ids = this.dataInfo.id;
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            downPic(this.dataInfo.obsUrl, this.dataInfo.name);
          } else {
            this.$message.error(res.msg);
          }
          //   this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          //   this.pageLoading = false;
        });
    },
    // 下载图片
    downLoadImage() {
      this.saveDownloadRecords();
    },
  },
};
</script>

<style lang="less" scoped>
.work-info-wrapper {
  display: flex;
  justify-content: center;
  // min-height: 100%;
  height: 100%;
  // padding-top: 20px;
  .img-wrapper {
    width: 520px;
    min-height: 100%;
    height: 100%;
    background: #f0f0f0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    > img {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .info-wrapper {
    // flex: 1;
    width: 320px;
    padding-left: 20px;
    .c-wrapper {
      padding: 20px;
      background: #fafafa;
      border: 1px solid #d2d2d2;
      border-radius: 6px;
      margin-bottom: 15px;
    }
    .basic-wrapper {
      padding-bottom: 30px;
      ul {
        > li {
          margin-bottom: 5px;
          p {
            line-height: 22px;
          }
          font-size: 14px;
          .name {
            color: #5a5a5a;
          }
          .value {
            color: #3d3d3d;
            font-weight: 700;
          }
        }
      }
    }
    .colors-wrapper {
      padding: 16px 10px 10px 10px;
      .prop-color-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .color-caard-item {
          width: 19%;
          height: 26px;
          margin-bottom: 6px;
          border-radius: 2px;
          cursor: pointer;
          transition: all ease 0.38s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .user-wrapper {
      .name {
        color: #5a5a5a;
        line-height: 40px;
      }
      .value {
        display: flex;
        align-items: center;
        > span {
          margin-left: 10px;
          color: #3d3d3d;
          font-weight: 700;
        }
      }
    }
    .download-wrapper {
      padding-top: 20px;
      text-align: center;
      .download-btn {
        width: 162px;
        height: 40px;
        background: #8565f6;
        border-radius: 20px;
        color: #fff;
        &:hover {
          background: #673ff5;
        }
      }
    }
    .tag-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-tag {
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: #fff;
        color: #3c3c3c;
        .el-tag__close {
          color: #3c3c3c;
        }
      }
    }
  }
}
</style>
